import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from '@azure/communication-common';
import {
  AvatarPersonaData,
  AzureCommunicationCallAdapterArgs,
  CallAdapterState,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallAdapter,
} from '@azure/communication-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IError } from '@guider-global/shared-types';

import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useVideoTokens } from 'hooks/useVideoTokens';
import { useVideos } from 'hooks/useVideos';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getVideoRoomState,
  setCallCompositePage,
} from 'store/slices/videoRoomSlice';

import { usePicture } from '@guider-global/azure-storage-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import VideoRoom from 'components/VideoRoom';
import {
  useLocalization,
  useProfiles,
  useRelationships,
  useUsers,
  useVideoParticipants,
} from 'hooks';

const VideoPage: FC = () => {
  // Organization and Base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({
    localeCode,
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  // Local State
  const [joinedAt, setJoinedAt] = useState<Date>();
  const [submittedTimeInCall, setSubmittedTimeInCall] =
    useState<boolean>(false);
  const [error, setError] = useState<IError[]>([]);

  // React Router
  const navigate = useNavigate();
  const { id: sessionId } = useParams();

  // REDUX
  const dispatch = useAppDispatch();
  const videoRoomState = useAppSelector(getVideoRoomState);
  const { transitionSidebar, callCompositePage } = videoRoomState;

  // Hooks
  const { getImage, loading: loadingPicture } = usePicture({
    containerName: 'pictures',
  });

  const { users } = useUsers({ getSilently: false });
  const user = users().at(0);

  const { getProfiles } = useProfiles({ getSilently: false });
  const profile = getProfiles().at(0);

  // Video Session
  const { video } = useVideos({
    sessionId: sessionId,
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  const {
    displayName,
    roomId,
    communicationUserId,
    relationshipId,
    sessionName,
  } = video?.[0] ?? {};

  const {
    videoParticipants,
    reqVideoParticipants,
    isLoadingVideoParticipants,
  } = useVideoParticipants({
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });
  const videoParticipant = videoParticipants?.find(
    (videoParticipant) => videoParticipant.session === sessionId,
  );

  // Relationship
  const { relationships: getRelationships, isLoadingRelationships } =
    useRelationships({
      getSilently: true,
      getSilentlyUrl: `/relationships/${relationshipId}`,
    });
  const relationships = getRelationships();
  const relationship = relationships.find(
    (relationship) => relationship.id === relationshipId,
  );
  const relationshipProfiles = [
    ...(relationship?.traineeProfiles ?? []),
    ...(relationship?.guideProfiles ?? []),
  ];

  const handleFetchUserImages = async (
    communicationUserId: string,
  ): Promise<AvatarPersonaData> => {
    const profile = relationshipProfiles.find(
      (profile) => profile?.communicationUserId === communicationUserId,
    );
    const user = relationship?.users?.find(
      (user) => user?.id === profile?.userId,
    );

    if (!user) return {};
    const imageUrl = profile?.picture
      ? getImage(profile?.picture)
      : user.picture;
    return { imageUrl };
  };

  // Users Video Token
  const { videoTokens, errorVideoTokens, isErrorVideoTokens } = useVideoTokens({
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  const { token } = videoTokens?.[0] ?? {};
  const errorsVideoToken = isErrorVideoTokens ? [errorVideoTokens] : [];

  // CMS

  const sanityBaseLanguageVideo =
    baseLanguage?.relationships?.sessions?.sessions_video;
  const {
    end_session_button_label: exitSessionLabel,
    toggle_chat_button_label: chatLabel,
    toggle_goals_button_label: goalsLabel,
    toggle_schedule_session_button_label: scheduleSessionLabel,
  } = sanityBaseLanguageVideo ?? {};

  // ACS Call Adapter
  const credential = useMemo(() => {
    try {
      if (!token) return undefined;
      return new AzureCommunicationTokenCredential(token);
    } catch (error) {
      console.warn(error);

      if (error instanceof Error) {
        setError([{ message: error.message }]);
      } else {
        setError([{ message: 'Failed to construct token credential' }]);
      }
      return undefined;
    }
  }, [token]);

  const callAdapterArgs: Partial<AzureCommunicationCallAdapterArgs> =
    useMemo(() => {
      if (!roomId || !communicationUserId) return {};
      return {
        userId: fromFlatCommunicationIdentifier(
          communicationUserId,
        ) as CommunicationUserIdentifier,
        displayName,
        credential,
        locator: { groupId: roomId },
      };
    }, [communicationUserId, credential, displayName, roomId]);

  const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

  useEffect(() => {
    if (!callAdapter) return;
    const disposeAdapter = async (): Promise<void> => {
      if (videoParticipant && joinedAt && !submittedTimeInCall) {
        setSubmittedTimeInCall(true);

        await reqVideoParticipants({
          method: 'PATCH',
          url: `/videoParticipants/${videoParticipant.id}`,
          data: {
            joinedAt,
            leftAt: new Date(),
          },
        });
      }

      callAdapter?.dispose();
    };

    const handlePageChange = (state: CallAdapterState) => {
      dispatch(setCallCompositePage(state.page));
    };
    callAdapter.onStateChange((state) => {
      handlePageChange(state);
    });
    window.addEventListener('beforeunload', disposeAdapter);
    return () => {
      callAdapter.offStateChange((state) => {
        handlePageChange(state);
      });
      window.removeEventListener('beforeunload', disposeAdapter);
    };
  }, [
    callAdapter,
    reqVideoParticipants,
    dispatch,
    navigate,
    submittedTimeInCall,
    relationshipId,
    joinedAt,
    videoParticipant,
  ]);

  useEffect(() => {
    if (isLoadingVideoParticipants) {
      return;
    }

    const handleCallCompositePage = async () => {
      if (callCompositePage === 'call') {
        const now = new Date();

        setSubmittedTimeInCall(false);

        if (!joinedAt || submittedTimeInCall) {
          setJoinedAt(now);
        }

        if (!videoParticipant && user && profile && sessionId && joinedAt) {
          await reqVideoParticipants({
            method: 'POST',
            url: '/videoParticipants',
            data: {
              organizationSlug,
              user: user.id,
              profile: profile.id,
              relationship: relationshipId,
              session: sessionId,
              joinedAt: now,
            },
          });
        }
      }

      if (
        callCompositePage === 'leftCall' &&
        videoParticipant &&
        joinedAt &&
        !submittedTimeInCall
      ) {
        setSubmittedTimeInCall(true);

        await reqVideoParticipants({
          method: 'PATCH',
          url: `/videoParticipants/${videoParticipant.id}`,
          data: {
            joinedAt,
            leftAt: new Date(),
          },
        });
      }
    };

    handleCallCompositePage();
  }, [
    reqVideoParticipants,
    submittedTimeInCall,
    isLoadingVideoParticipants,
    callCompositePage,
    videoParticipant,
    organizationSlug,
    user,
    profile,
    relationshipId,
    sessionId,
    joinedAt,
  ]);

  // Derivations

  const loading =
    isLoadingRelationships() ||
    !token ||
    !roomId ||
    !callAdapter ||
    loadingPicture;

  const errors = [...errorsVideoToken, ...error];

  return (
    <VideoRoom
      sessionTitle={sessionName ?? ''}
      exitSessionLabel={exitSessionLabel}
      relationshipId={relationshipId ?? ''}
      scheduleSessionLabel={scheduleSessionLabel}
      goalsLabel={goalsLabel}
      chatLabel={chatLabel}
      callAdapter={callAdapter}
      onFetchUserImages={handleFetchUserImages}
      localeCode={localeCode}
      transitionSidebar={transitionSidebar}
      loading={loading}
      errors={errors}
    />
  );
};

export default VideoPage;
