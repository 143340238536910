import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSettings,
} from '@guider-global/sanity-hooks';
import {
  Stack,
  Text,
  Image,
  theme,
  TextStack,
  SkeletonStack,
} from '@guider-global/ui';

import { ThemeProvider, useTheme } from '@mui/material';
import { ReviewContainer } from 'containers/Settings';

import { useLocalization, useReviews } from 'hooks';

import { deepMerge } from 'utils';

export function SettingsReviewsPage() {
  // Hooks
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);

  // Settings
  const { settings } = useSettings({
    localeCode,
  });

  const emptyStateAvatar = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.no_relationships ?? '',
    width: 180,
  });

  // Base Language

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const reviewsText = getBaseLanguage()?.settings?.reviews;

  // Reviews
  const { reviews, isLoadingReviews } = useReviews({});

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette }}>
      <Stack direction={'column'} pb={3}>
        <TextStack
          size={'medium'}
          heading={{ text: reviewsText?.title, variant: 'h2' }}
          subtitles={[
            {
              text: reviewsText?.description,
              variant: 'body1',
              color: 'text.secondary',
            },
          ]}
        />
        {reviews &&
          reviews.length !== 0 &&
          reviews
            .sort((a, b) => (b.date > a.date ? 1 : -1))
            .map((review) => {
              return <ReviewContainer review={review} />;
            })}
        {!isLoadingReviews && (!reviews || reviews.length === 0) && (
          <Stack
            direction={'column'}
            alignItems={'center'}
            gap={0}
            spacing={0}
            width={'50%'}
            pt={2}
            alignSelf={'center'}
          >
            <Text variant="h3" text={reviewsText?.empty_state_title} mb={2} />
            {emptyStateAvatar && (
              <Image src={emptyStateAvatar} fit="contain" width={'80%'} />
            )}
            <Text
              textAlign={'center'}
              variant="caption"
              text={reviewsText?.empty_state_description}
            />
          </Stack>
        )}
        {isLoadingReviews && (
          <SkeletonStack
            amount={1}
            direction={'column'}
            skeletonHeight="150px"
          />
        )}
      </Stack>
    </ThemeProvider>
  );
}
