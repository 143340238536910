import { ThemeProvider, useTheme } from '@mui/material';
import { theme } from '@guider-global/ui';
import { useProfiles } from 'hooks';
import { deepMerge } from 'utils';
import { EditProfileForm } from 'forms/EditProfileForm';
import { ProfilePictureForm } from 'forms';

export function SettingsProfilePage() {
  // Theme
  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);

  // Profiles
  const { getProfiles } = useProfiles({
    getSilently: false,
    forceRefresh: false,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });
  const profiles = getProfiles();

  const profile = profiles.at(0);

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette }}>
      <ProfilePictureForm />
      <EditProfileForm profile={profile} />
    </ThemeProvider>
  );
}
