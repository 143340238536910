import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { SettingsDescription, SettingsSubheader } from 'components';
import { AvailabilityForm } from 'forms';
import { useAvailabilities, useLocalization } from 'hooks';
import { getSubDomain } from '@guider-global/front-end-utils';

export function SettingsAvailabilityPage() {
  const [requestedAvailabilities, setRequestedAvailabilities] = useState(false);

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const availability = baseLanguage?.settings?.availability;
  const {
    section_title: sectionTitle,
    section_description: sectionDescription,
  } = availability ?? {};

  const { reqAvailabilities, isLoadingAvailabilities } = useAvailabilities({});

  useEffect(() => {
    const requestLatestAvailabilities = async () => {
      console.log('Requesting availabilities');
      if (!requestedAvailabilities && !isLoadingAvailabilities) {
        try {
          await reqAvailabilities({
            method: 'GET',
            url: '/availabilities',
          });

          setRequestedAvailabilities(true);
        } catch (error) {
          console.error('Error fetching availabilities:', error);
        }
      }
    };

    requestLatestAvailabilities();
  }, [requestedAvailabilities, isLoadingAvailabilities, reqAvailabilities]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        '& > .MuiTypography-h6': {
          mb: 0.5,
        },
      }}
    >
      <SettingsSubheader subheader={sectionTitle} />
      <SettingsDescription description={sectionDescription} />

      <AvailabilityForm />
    </Box>
  );
}
